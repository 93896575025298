/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '65e1c6343f03d7ac79b53006',
          i18n: {
            fr: {
              title: 'En immersion',
              description: `[interne] Au plus près des collaborateurs du groupe AFD`,
            },
          },
          heading: {
            color: '#EE7656',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '65e1c62a3f03d70bd6b53000',
          i18n: {
            fr: {
              title: 'Moments choisis',
              description: `[interne] Les confidences des collaborateurs du groupe AFD`,
            },
          },
          heading: {
            color: '#96D3DD',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '66d96baa963f2b04091b3fb1',
          i18n: {
            fr: {
              title: "Les pionnières",
              description: `[interne] Le podcast qui donne la parole à celles qui ont bousculé les codes et fait bouger les lignes à l’AFD`,
            },
          },
          heading: {
            color: '#003050',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '66d9bfe0963f2be8101b410a',
          i18n: {
            fr: {
              title: "Et l'éthique dans tout ça ?",
              description: `[interne] Le podcast qui explique le Conseil Éthique`,
            },
          },
          heading: {
            color: '#00983A',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '652cff4056e877210534819a',
          i18n: {
            fr: {
              title: `Hivernales`,
              description: `[exclusif] Le podcast quotidien pour (re)vivre l'édition 2024 !`,
            },
          },
          heading: {
            color: '#14375A',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/hivernales-mobile.png')
                : require('~/assets/images/hivernales-desktop.png')
            },
          },
        },
        {
          type: 'tag',
          tagId: '652cff4d56e87769e13481a0',
          i18n: {
            fr: {
              title: 'Grandes lignes',
              description: `Le podcast de la recherche sur le développement de l'AFD`,
            },
          },
          heading: {
            color: 'primary',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/grandes-lignes-mobile.png')
                : require('~/assets/images/grandes-lignes-desktop.png')
            },
          },
        },
        {
          type: 'tag',
          tagId: '652cff5756e877fdf23481a6',
          i18n: {
            fr: {
              title: 'Des nouvelles de demain',
              description: `Le Campus AFD qui donne la parole à des personnalités du monde entier pour réfléchir à un avenir désirable`,
            },
          },
          heading: {
            color: '#B0D0EF',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/des-nouvelles-de-demain-mobile.png')
                : require('~/assets/images/des-nouvelles-de-demain-desktop.png')
            },
          },
          filters: {
            type: 'solo',
            soloTagId: '6542b4020ce29a65b6f3a896',
          },
        },
        {
          type: 'tag',
          tagId: '658d9033842dab77196b4197',
          i18n: {
            fr: {
              title: 'Six pieds sur Terre',
              description: `Le podcast de Courrier international et l’AFD sur le développement durable`,
            },
          },
          heading: {
            color: '#E96E98',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/six-pieds-sur-terre-mobile.png')
                : require('~/assets/images/six-pieds-sur-terre-desktop.png')
            },
          },
          filters: {
            type: 'solo',
            soloTagId: '6576d44d5d67368c5c543fb3',
            sort: (category) => {
              const findTag = (slug, tags) => {
                return tags.find((tag) => tag.slug === slug)
              }
              const $clone = require('lodash.clone')
              const clonedCategory = $clone(category)
              const subTags = clonedCategory.tags
              const displaidTags = []
              displaidTags.push(
                findTag(
                  'premiere-serie-:-comment-vivre-avec-le-vivant',
                  subTags
                )
              )
              displaidTags.push(
                findTag("deuxieme-serie-:-de-l'eau-pour-tous", subTags)
              )
              displaidTags.push(
                findTag('troisieme-serie-:-quelles-villes-pour-demain', subTags)
              )
              displaidTags.push(
                findTag(
                  "quatrieme-serie-:-l'ocean-sanctuaire-ou-eldorado",
                  subTags
                )
              )
              displaidTags.push(
                findTag('cinquieme-serie-:-demain-dans-nos-assiettes', subTags)
              )
              displaidTags.push(
                findTag("sixieme-serie-:-l'appel-de-la-foret", subTags)
              )

              clonedCategory.tags = displaidTags.filter(Boolean)
              return clonedCategory
            },
          },
        },
        {
          type: 'tag',
          tagId: '652cff6456e877c8093481ac',
          i18n: {
            fr: {
              title: 'Les Sentinelles',
              description: `Des voix d’ailleurs pour un monde en commun`,
            },
          },
          heading: {
            color: '#010101',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            backgroundImage(context) {
              return context.$voicer.isMobile
                ? require('~/assets/images/sentinelles-mobile.png')
                : require('~/assets/images/sentinelles-desktop.png')
            },
          },
        },
      ],
    },
  ],
}